import Logo from "../img/logo_negro_sm.svg";

const Footer = () => {
  return (
    <footer className="py-3 my-4 mb-0">
      <div
        className="col-md-12 d-flex align-items-center justify-content-between"
        style={{ padding: "0 20px" }}
      >
        <div className="d-flex flex-column align-items-start">
          <p>
            <h5>
              <b>DESARROLLADOR</b>
            </h5>{" "}
            - EMANUEL ALLARIA
          </p>
          <p>
            <h5>
              <b>DISEÑADOR</b>
            </h5>{" "}
            - NICOLÁS OSORIO
          </p>
        </div>
        <img src={Logo} alt="Logo Near-U chico" />
      </div>
      <br />
      <p style={{ color: "#000" }} className="text-center mb-0">
        © {new Date().getFullYear()} Todos los derechos reservados. Typing
        Timing.
        <br />
        <a className="text-decoration-none" href="/privacy-policy">
          Politicas y Privacidades
        </a>
      </p>
    </footer>
  );
};

export default Footer;
