import { useEffect, useState } from "react";
import { Pagination, Modal, Button } from "react-bootstrap";
import {
  DeleteApi,
  GetApi,
  GetSingleApi,
  PostApi,
  PutApi,
} from "../api/conection";
import Logo from "../img/logo_negro_sm.svg";

function Profile() {
  const [usuario, setUsuario] = useState(null);
  const [loading, setLoading] = useState(false);
  const [servicios, setServicios] = useState(null);
  const [serviciosAdquiridos, setServiciosAdquiridos] = useState(null);
  const [clientes, setClientes] = useState(null);
  const [showModalEliminar, setShowModalEliminar] = useState(false);
  const [showModalEditar, setShowModalEditar] = useState(false);
  const [showModalAddNewJob, setShowModalAddNewJob] = useState(false);
  const [showModalAddNewService, setShowModalAddNewService] = useState(false);
  const [servicioSeleccionado, setServicioSeleccionado] = useState(null);
  const [portfolio, setPortfolio] = useState(null);
  const [portfolioNew, setPortfolioNew] = useState(null);
  const [servicioCreadoNombre, setServicioCreadoNombre] = useState("");
  const [servicioCreadoDescription, setServicioCreadoDescription] =
    useState("");
  const [servicioCreadoTipo, setServicioCreadoTipo] = useState("remoto");

  const handleEliminarClick = (id) => {
    setShowModalEliminar(true);
    setServicioSeleccionado(id);
  };

  const handleEditarClick = (service) => {
    setShowModalEditar(true);
    setServicioSeleccionado(service);
  };

  const eliminarServicio = async () => {
    if (servicioSeleccionado) {
      await DeleteApi("/service", servicioSeleccionado);
      setShowModalEliminar(false);
      setServicioSeleccionado(null);
      window.location.reload();
    }
  };

  const editarServicio = async () => {
    if (servicioSeleccionado && servicioSeleccionado.id) {
      await PutApi("/service", servicioSeleccionado.id, servicioSeleccionado);
      setShowModalEditar(false);
      setServicioSeleccionado(null);
      window.location.reload();
    }
  };

  const crearServicio = async () => {
    const data = {
      nombre: servicioCreadoNombre,
      description: servicioCreadoDescription,
      tipo: servicioCreadoTipo,
      user_id: usuario.id,
    };
    await PostApi("/service", data)
      .then(() => {
        setShowModalAddNewJob(false);
        setServicioCreadoNombre("");
        setServicioCreadoDescription("");
        setServicioCreadoTipo("");
        window.location.reload();
      })
      .catch((err) => console.error(err));
  };

  const handleAddNewJob = () => {
    setShowModalAddNewJob(true);
  };

  const handleAddNewService = () => {
    setShowModalAddNewService(true);
  };

  const addNewJob = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("imagen", portfolioNew.imagen);
    formData.append("nombre", portfolioNew.nombre);
    formData.append("user_id", usuario.id);
    formData.append("_method", "POST");
    try {
      await PostApi("/portfolio", formData)
        .then(() => window.location.reload())
        .catch((err) => console.error(err));
    } catch (err) {
      console.error(err);
    }
  };

  const handleUploadImagenServicio = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    setPortfolioNew({ ...portfolioNew, imagen: file });
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        let res = null;
        if (
          window.location.pathname.split("/profile/").length > 1 &&
          window.location.pathname.split("/profile/")[1] !== ""
        ) {
          res = await GetSingleApi(
            "/user",
            window.location.pathname.split("/profile/")[1]
          );
        } else {
          const userFromStorage = JSON.parse(localStorage.getItem("user_id"));
          res = await GetSingleApi("/user", userFromStorage);
        }
        const user = res.data || res;
        setUsuario(user);
      } catch (err) {
        if (err.response.data.message === "Usuario no encontrado") {
          alert("No existe ese usuario");
          window.location.href = "/home";
        }
        console.error(err);
      }
      setLoading(false);
    };

    fetchData();
  }, [window.location.pathname]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const servicesRes = await GetApi(`/services?user_id=${usuario.id}`);
        const services = servicesRes.data;

        for (const service of services) {
          const userRes = await GetSingleApi("/user", service.user_id);
          const userData = userRes.data;

          const servicesRequestsData = await GetApi(
            `/service_request?service_id=${service.id}`
          );

          const totalRatings = servicesRequestsData.data.reduce(
            (acc, curr) => acc + curr.rating,
            0
          );
          const averageRating = totalRatings / servicesRequestsData.data.length;

          const clientesRealizados = servicesRequestsData.data.filter(
            (request) => request.activo === 0 && request.terminado === 1
          ).length;
          const clientesEnProceso = servicesRequestsData.data.filter(
            (request) => request.activo === 1 && request.terminado === null
          ).length;

          service.vendedor = userData;
          service.rating = averageRating || 0;
          service.clientesRealizados = clientesRealizados;
          service.clientesEnProceso = clientesEnProceso;
        }

        setServicios(services);
      } catch (err) {
        console.error(err);
      }
      setLoading(false);
    };

    if (usuario && usuario.id) {
      fetchData();
    }
  }, [usuario]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const servicesRes = await GetApi(
          `/service_request?cliente_id=${usuario.id}`
        );
        const services = servicesRes.data;

        for (const service of services) {
          if (
            service.active !== 1 &&
            (service.terminado !== 0 || service.terminado !== null)
          ) {
            const userRes = await GetSingleApi("/user", service.vendedor_id);
            const userData = userRes.data;

            const serviceRes = await GetSingleApi(
              "/service",
              service.service_id
            );
            const serviceResData = serviceRes.data;

            service.service = serviceResData;
            service.vendedor = userData;
          } else {
            return null;
          }
        }

        setServiciosAdquiridos(services);
      } catch (err) {
        console.error(err);
      }
      setLoading(false);
    };

    if (usuario && usuario.id) {
      fetchData();
    }
  }, [usuario]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const portfolioRes = await GetApi(`/portfolios?user_id=${usuario.id}`);
        const portfolios = portfolioRes.data;

        setPortfolio(portfolios);
      } catch (err) {
        console.error(err);
      }
      setLoading(false);
    };

    if (usuario && usuario.id) {
      fetchData();
    }
  }, [usuario]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const clientesRes = await GetApi(
          `/service_request?vendedor_id=${usuario.id}`
        );
        const clientes = clientesRes.data;

        const uniqueClientes = {};
        for (const cliente of clientes) {
          if (
            cliente.active !== 1 &&
            (cliente.terminado !== 0 || cliente.terminado !== null)
          ) {
            const clienteId = cliente.cliente_id;
            if (!uniqueClientes[clienteId]) {
              const userRes = await GetSingleApi("/user", clienteId);
              const userData = userRes.data;

              uniqueClientes[clienteId] = {
                ...cliente,
                cliente: userData,
                servicios: [],
              };

              const serviciosDeCliente = servicios.filter(
                (servicio) => servicio.id === cliente.service_id
              );
              uniqueClientes[clienteId].servicios = serviciosDeCliente;
            } else {
              const serviciosDeCliente = servicios.filter(
                (servicio) => servicio.id === cliente.service_id
              );
              uniqueClientes[clienteId].servicios.push(...serviciosDeCliente);
            }
          }
        }

        const clientesArray = Object.values(uniqueClientes);
        setClientes(clientesArray);
      } catch (err) {
        console.error(err);
      }
      setLoading(false);
    };

    if (usuario && usuario.id) {
      fetchData();
    }
  }, [usuario, servicios]);

  // Paginación Mis Servicios
  const [currentPage, setCurrentPage] = useState(1);
  const serviciosPerPage = 5;

  // Obtener índices de servicios actuales
  const indexOfLastServicio = currentPage * serviciosPerPage;
  const indexOfFirstServicio = indexOfLastServicio - serviciosPerPage;
  const currentServicios =
    servicios && servicios.slice(indexOfFirstServicio, indexOfLastServicio);

  // Cambiar de página
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Paginación Clientes
  const [currentPageClientes, setCurrentPageClientes] = useState(1);
  const serviciosPerPageClientes = 5;

  // Obtener índices de servicios actuales
  const indexOfLastServicioClientes =
    currentPageClientes * serviciosPerPageClientes;
  const indexOfFirstServicioClientes =
    indexOfLastServicioClientes - serviciosPerPageClientes;
  const currentServiciosClientes =
    clientes &&
    clientes.slice(indexOfFirstServicioClientes, indexOfLastServicioClientes);

  // Cambiar de página
  const paginateClientes = (pageNumber) => setCurrentPageClientes(pageNumber);

  // Paginación Servicios Adquiridos
  const [currentPageAdquiridos, setCurrentPageAdquiridos] = useState(1);
  const serviciosPerPageAdquiridos = 5;

  // Obtener índices de servicios actuales
  const indexOfLastServicioAdquiridos =
    currentPageAdquiridos * serviciosPerPageAdquiridos;
  const indexOfFirstServicioAdquiridos =
    indexOfLastServicioAdquiridos - serviciosPerPageAdquiridos;
  const currentServiciosAdquiridos =
    serviciosAdquiridos &&
    serviciosAdquiridos.slice(
      indexOfFirstServicioAdquiridos,
      indexOfLastServicioAdquiridos
    );

  // Cambiar de página
  const paginateAdquiridos = (pageNumber) =>
    setCurrentPageAdquiridos(pageNumber);

  return (
    <div
      style={{
        width: "100%",
        padding: 20,
        background: "linear-gradient(270deg, #375189, #308ab4)",
        borderRadius: "0 0 20px 20px",
      }}
    >
      <div className="column">
        <h1 style={{ textAlign: "center", color: "#000" }}>NEAR-U</h1>
        <div className="col-lg-12 mb-4">
          <div className="card text-white">
            <div className="card-body">
              {loading && !usuario && (
                <div className="col-md-12 d-flex flex-column align-items-center justify-content-center">
                  <img src={Logo} alt="Logo Near-u" height="100" id="loader" />
                  <h4>Cargando...</h4>
                </div>
              )}
              <div className="d-flex flex-row align-items-center justify-content-between profile">
                <div>
                  <img
                    src={
                      usuario?.image
                        ? `${process.env.REACT_APP_IMAGES_URL}${usuario?.image}`
                        : "https://tse3.mm.bing.net/th?id=OIP.6Zu4MaXfS1aM1EdA31OweQAAAA&pid=Api&P=0&h=180"
                    }
                    alt="Imagen del usuario"
                    style={{ borderRadius: "50%", width: 150, height: 150 }}
                    title="Foto de Perfil"
                  />
                  <p
                    className="card-text"
                    style={{ color: "#000" }}
                    title="Nombre de Usuario"
                  >
                    <strong>{usuario?.username}</strong>
                  </p>
                  <h2 className="card-text" style={{ color: "#000" }}>
                    <strong>
                      <span title="Nombre">{usuario?.firstname}</span>{" "}
                      <span title="Apellido">{usuario?.lastname}</span>
                    </strong>
                  </h2>
                  <p
                    className="card-text"
                    style={{ color: "#000" }}
                    title="Descripción"
                  >
                    <strong>{usuario?.description}</strong>
                  </p>
                  <p className="card-text" style={{ color: "#000" }}>
                    <span title="Distrito">{usuario?.district}</span>,{" "}
                    <span title="Provincia">{usuario?.province}</span>,{" "}
                    <span title="Pais">{usuario?.country}</span>
                  </p>
                </div>
                <div>
                  <p className="card-text" style={{ color: "#000" }}>
                    <strong>Contacto:</strong>
                  </p>
                  <p
                    className="card-text"
                    title="Email"
                    style={{ color: "#000" }}
                  >
                    {usuario?.email}
                  </p>
                  <p
                    className="card-text"
                    title="Numero de Telefono"
                    style={{ color: "#000" }}
                  >
                    {usuario?.codigo_telefono} {usuario?.phone}
                  </p>
                  <div className="row">
                    {usuario?.instagram && (
                      <div className="col" title="Instagram">
                        <a
                          target="_blank"
                          style={{ color: "#000" }}
                          href={usuario?.instagram}
                        >
                          <i class="fa-brands fa-instagram"></i>
                        </a>
                      </div>
                    )}
                    {usuario?.youtube && (
                      <div className="col" title="Youtube">
                        <a
                          target="_blank"
                          style={{ color: "#000" }}
                          href={usuario?.youtube}
                        >
                          <i class="fa-brands fa-youtube"></i>
                        </a>
                      </div>
                    )}
                    {usuario?.facebook && (
                      <div className="col" title="Facebook">
                        <a
                          target="_blank"
                          style={{ color: "#000" }}
                          href={usuario?.facebook}
                        >
                          <i class="fa-brands fa-facebook"></i>
                        </a>
                      </div>
                    )}
                    {usuario?.website && (
                      <div className="col" title="Globe">
                        <a
                          target="_blank"
                          style={{ color: "#000" }}
                          href={usuario?.website}
                        >
                          <i class="fa-solid fa-globe"></i>
                        </a>
                      </div>
                    )}
                    {usuario?.linkedin && (
                      <div className="col" title="Linkedin">
                        <a
                          target="_blank"
                          style={{ color: "#000" }}
                          href={usuario?.linkedin}
                        >
                          <i class="fa-brands fa-linkedin"></i>
                        </a>
                      </div>
                    )}
                    {usuario?.behance && (
                      <div className="col" title="Behance">
                        <a
                          target="_blank"
                          style={{ color: "#000" }}
                          href={usuario?.behance}
                        >
                          <i class="fa-brands fa-behance"></i>
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="col-lg-12">
          <h2 className="mb-4" style={{ color: "#fff" }}>
            Mis Servicios
          </h2>
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={handleAddNewService}
          >
            Agregar Nuevo Servicio
          </button>
          <div className="row">
            {loading && !currentServicios && (
              <div className="col-md-12 d-flex flex-column align-items-center justify-content-center">
                <img src={Logo} alt="Logo Near-u" height="100" id="loader" />
                <h4>Cargando...</h4>
              </div>
            )}
            {currentServicios &&
              currentServicios.map((servicio) => (
                <div key={servicio.Id} className="col-md-6 col-lg-4 mb-4">
                  <div className="card text-black h-100">
                    <img
                      src={
                        servicio?.vendedor?.image
                          ? `${process.env.REACT_APP_IMAGES_URL}${servicio?.vendedor?.image}`
                          : "https://tse3.mm.bing.net/th?id=OIP.6Zu4MaXfS1aM1EdA31OweQAAAA&pid=Api&P=0&h=180"
                      }
                      className="card-img-top rounded-circle mx-auto d-block mt-3"
                      alt="User Image"
                      style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "cover",
                      }}
                    />
                    <p
                      style={{
                        color: "#000",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      {servicio?.vendedor?.firstname}{" "}
                      {servicio?.vendedor?.lastname}
                    </p>
                    <hr width="100%" />
                    <div className="card-body">
                      <h5 className="card-title" style={{ color: "#000" }}>
                        {servicio?.nombre}
                      </h5>
                      <p className="card-text" style={{ color: "#000" }}>
                        {servicio?.description}
                      </p>
                      <p className="card-text" style={{ color: "#000" }}>
                        Trabajos realizados: {servicio?.clientesRealizados}{" "}
                        <br />
                        Trabajos pendientes: {servicio?.clientesEnProceso}
                      </p>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="btn-group">
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-primary"
                            onClick={() => handleEditarClick(servicio)}
                          >
                            Editar
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-secondary"
                            onClick={() => handleEliminarClick(servicio?.id)}
                          >
                            Eliminar
                          </button>
                        </div>
                        <div className="d-flex align-items-center gap-1">
                          <span className="text-body-primary">
                            Calificación:&nbsp;
                          </span>
                          <p style={{ margin: 0, color: "#000" }}>
                            {servicio?.rating}/5
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          {/* Paginación */}
          <div className="d-flex justify-content-center mt-3">
            <Pagination>
              {Array.from({
                length: Math.ceil(
                  servicios && servicios.length / serviciosPerPage
                ),
              }).map((_, index) => (
                <Pagination.Item
                  key={index}
                  active={index + 1 === currentPage}
                  onClick={() => paginate(index + 1)}
                >
                  {index + 1}
                </Pagination.Item>
              ))}
            </Pagination>
          </div>
        </div>
        <br />
        <br />
        <div className="col-lg-12">
          <h2 className="mb-4" style={{ color: "#fff" }}>
            Mis Clientes
          </h2>
          <div className="row">
            {loading && !currentServiciosClientes && (
              <div className="col-md-12 d-flex flex-column align-items-center justify-content-center">
                <img src={Logo} alt="Logo Near-u" height="100" id="loader" />
                <h4>Cargando...</h4>
              </div>
            )}
            {currentServiciosClientes &&
              currentServiciosClientes.map((cliente, index) => (
                <div key={index} className="col-md-6 col-lg-4 mb-4">
                  <div className="card text-black h-100 d-flex flex-column align-items-center justify-content-center">
                    <div className="d-flex flex-row align-items-center justify-content-center">
                      <img
                        src={cliente?.cliente?.image}
                        className="card-img-top rounded-circle mx-auto"
                        alt="User Image"
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                        }}
                      />
                      <img
                        src={
                          cliente?.cliente?.image
                            ? `${process.env.REACT_APP_IMAGES_URL}${cliente?.cliente?.image}`
                            : "https://tse3.mm.bing.net/th?id=OIP.6Zu4MaXfS1aM1EdA31OweQAAAA&pid=Api&P=0&h=180"
                        }
                        alt="Imagen del usuario"
                        style={{ borderRadius: "50%", width: 150, height: 150 }}
                      />
                      <div className="col-md-8 row align-items-center">
                        <h5
                          className="card-title"
                          style={{
                            color: "#000",
                          }}
                        >
                          {cliente?.cliente?.firstname}{" "}
                          {cliente?.cliente?.lastname}
                        </h5>
                      </div>
                    </div>
                    <hr width="100%" />
                    <div className="d-flex flex-column align-items-start">
                      {cliente?.servicios &&
                        cliente?.servicios.map((servicio) => (
                          <h6
                            style={{
                              color: "#000",
                              textAlign: "center",
                              // fontWeight: 700,
                            }}
                          >
                            Servicio Comprado: {servicio?.nombre}
                          </h6>
                        ))}
                    </div>
                  </div>
                </div>
              ))}
          </div>
          {/* Paginación */}
          <div className="d-flex justify-content-center mt-3">
            <Pagination>
              {Array.from({
                length: Math.ceil(
                  clientes && clientes.length / serviciosPerPage
                ),
              }).map((_, index) => (
                <Pagination.Item
                  key={index}
                  active={index + 1 === currentPage}
                  onClick={() => paginateClientes(index + 1)}
                >
                  {index + 1}
                </Pagination.Item>
              ))}
            </Pagination>
          </div>
        </div>
        <br />
        <br />
        <div className="col-lg-12">
          {currentServiciosAdquiridos && (
            <div className="col-lg-12">
              <h2 className="mb-4" style={{ color: "#fff" }}>
                Servicios Adquiridos
              </h2>
              <div className="row">
                {loading && !currentServiciosAdquiridos && (
                  <div className="col-md-12 d-flex flex-column align-items-center justify-content-center">
                    <img
                      src={Logo}
                      alt="Logo Near-u"
                      height="100"
                      id="loader"
                    />
                    <h4>Cargando...</h4>
                  </div>
                )}
                {currentServiciosAdquiridos &&
                  currentServiciosAdquiridos.map((servicio, index) => (
                    <div key={index} className="col-md-6 col-lg-4 mb-4">
                      <div className="card text-black h-100">
                        <img
                          src={
                            servicio?.cliente?.image
                              ? `${process.env.REACT_APP_IMAGES_URL}${servicio?.vendedor?.image}`
                              : "https://tse3.mm.bing.net/th?id=OIP.6Zu4MaXfS1aM1EdA31OweQAAAA&pid=Api&P=0&h=180"
                          }
                          className="card-img-top rounded-circle mx-auto d-block mt-3"
                          alt="User Image"
                          style={{
                            width: "100px",
                            height: "100px",
                            objectFit: "cover",
                          }}
                        />
                        <p
                          style={{
                            color: "#000",
                            width: "100%",
                            textAlign: "center",
                          }}
                        >
                          {servicio?.vendedor?.firstname}{" "}
                          {servicio?.vendedor?.lastname}
                        </p>
                        {console.log(servicio)}
                        <hr width="100%" />
                        <div className="card-body">
                          <h5 className="card-title" style={{ color: "#000" }}>
                            {servicio?.service?.nombre}{" "}
                            <a
                              target="_blank"
                              href={`/recibo/${servicio.recibo.service_request_id}`}
                            >
                              <i class="fa-solid fa-receipt"></i>
                            </a>
                          </h5>
                          <p className="card-text" style={{ color: "#000" }}>
                            {servicio?.service?.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              {/* Paginación */}
              <div className="d-flex justify-content-center mt-3">
                <Pagination>
                  {Array.from({
                    length: Math.ceil(
                      serviciosAdquiridos &&
                        serviciosAdquiridos.length / serviciosPerPage
                    ),
                  }).map((_, index) => (
                    <Pagination.Item
                      key={index}
                      active={index + 1 === currentPage}
                      onClick={() => paginateAdquiridos(index + 1)}
                    >
                      {index + 1}
                    </Pagination.Item>
                  ))}
                </Pagination>
              </div>
            </div>
          )}
        </div>
        <br />
        <br />
        <div className="col-lg-12 mb-5">
          {portfolio && (
            <div className="col-lg-12">
              <h2 className="mb-4" style={{ color: "#fff" }}>
                Portfolio
              </h2>
              <button
                type="button"
                className="btn btn-sm btn-primary"
                onClick={handleAddNewJob}
              >
                Agregar Nuevo Trabajo
              </button>
              <div className="row">
                {loading && !portfolio && (
                  <div className="col-md-12 d-flex flex-column align-items-center justify-content-center">
                    <img
                      src={Logo}
                      alt="Logo Near-u"
                      height="100"
                      id="loader"
                    />
                    <h4>Cargando...</h4>
                  </div>
                )}
                {portfolio &&
                  portfolio.map((trabajo, index) => (
                    <div key={index} className="col-md-4 mb-5">
                      <img
                        src={
                          trabajo?.imagen
                            ? `${process.env.REACT_APP_IMAGES_URL}${trabajo?.imagen}`
                            : "https://tse3.mm.bing.net/th?id=OIP.6Zu4MaXfS1aM1EdA31OweQAAAA&pid=Api&P=0&h=180"
                        }
                        className="card-img-top mx-auto d-block mt-3"
                        alt="User Image"
                        style={{ height: "100%" }}
                      />
                      <p
                        style={{
                          color: "#fff",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        {trabajo?.nombre}
                      </p>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>
      {/* modal de crear servicio */}
      <Modal
        show={showModalAddNewService}
        onHide={() => setShowModalAddNewService(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Crear servicio</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
            <div className="mb-3">
              <label
                htmlFor="nombreServicio"
                className="form-label"
                style={{ color: "#000" }}
              >
                Nombre del servicio:
              </label>
              <input
                type="text"
                className="form-control"
                id="nombreServicio"
                value={servicioCreadoNombre}
                onChange={(e) => setServicioCreadoNombre(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="descriptionServicio"
                className="form-label"
                style={{ color: "#000" }}
              >
                Descripción del servicio:
              </label>
              <textarea
                className="form-control"
                id="descriptionServicio"
                value={servicioCreadoDescription}
                onChange={(e) => setServicioCreadoDescription(e.target.value)}
                cols="30"
                rows="10"
              ></textarea>
            </div>
            <div className="mb-3">
              <label
                htmlFor="tipoServicio"
                className="form-label"
                style={{ color: "#000" }}
              >
                Tipo del servicio:
              </label>
              <select
                className="form-control"
                id="tipoServicio"
                value={servicioCreadoTipo}
                onChange={(e) => setServicioCreadoTipo(e.target.value)}
              >
                <option selected value="remoto">
                  Remoto
                </option>
                <option value="presencial">Presencial</option>
              </select>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowModalAddNewService(false)}
          >
            Cancelar
          </Button>{" "}
          <Button variant="primary" onClick={crearServicio}>
            Crear
          </Button>{" "}
        </Modal.Footer>
      </Modal>
      {/* modal de crear nuevo trabajo */}
      <Modal
        show={showModalAddNewJob}
        onHide={() => setShowModalAddNewJob(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirmar Eliminación</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
            <div className="mb-3">
              <label
                htmlFor="nombreServicio"
                className="form-label"
                style={{ color: "#000" }}
              >
                Nombre del servicio:
              </label>
              <input
                type="text"
                className="form-control"
                id="nombreServicio"
                value={portfolioNew && portfolioNew.nombre}
                onChange={(e) =>
                  setPortfolioNew({
                    ...portfolioNew,
                    nombre: e.target.value,
                  })
                }
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="imagenServicio"
                className="form-label"
                style={{ color: "#fff" }}
              >
                Imagen del servicio:
              </label>
              <input
                type="file"
                className="form-control"
                id="imagenServicio"
                onChange={handleUploadImagenServicio}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowModalAddNewJob(false)}
          >
            Cancelar
          </Button>{" "}
          <Button variant="primary" onClick={addNewJob}>
            Subir trabajo
          </Button>{" "}
        </Modal.Footer>
      </Modal>
      {/* modal de eliminar servicio */}
      <Modal
        show={showModalEliminar}
        onHide={() => setShowModalEliminar(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirmar Eliminación</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          ¿Estás seguro de que deseas eliminar este servicio?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowModalEliminar(false)}
          >
            Cancelar
          </Button>{" "}
          <Button variant="primary" onClick={eliminarServicio}>
            Eliminar
          </Button>{" "}
        </Modal.Footer>
      </Modal>
      {/* modal de editar servicio */}
      <Modal show={showModalEditar} onHide={() => setShowModalEditar(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Editar servicio seleccionado</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
            <div className="mb-3">
              <label
                htmlFor="nombreServicio"
                className="form-label"
                style={{ color: "#000" }}
              >
                Nombre del servicio:
              </label>
              <input
                type="text"
                className="form-control"
                id="nombreServicio"
                value={servicioSeleccionado && servicioSeleccionado.nombre}
                onChange={(e) =>
                  setServicioSeleccionado({
                    ...servicioSeleccionado,
                    nombre: e.target.value,
                  })
                }
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="descriptionServicio"
                className="form-label"
                style={{ color: "#000" }}
              >
                Descripción del servicio:
              </label>
              <textarea
                className="form-control"
                id="descriptionServicio"
                value={servicioSeleccionado && servicioSeleccionado.description}
                onChange={(e) =>
                  setServicioSeleccionado({
                    ...servicioSeleccionado,
                    description: e.target.value,
                  })
                }
                cols="30"
                rows="10"
              ></textarea>
            </div>
            <div className="mb-3">
              <label
                htmlFor="tipoServicio"
                className="form-label"
                style={{ color: "#000" }}
              >
                Tipo del servicio:
              </label>
              <select
                className="form-control"
                id="tipoServicio"
                value={servicioSeleccionado && servicioSeleccionado.tipo}
                onChange={(e) =>
                  setServicioSeleccionado({
                    ...servicioSeleccionado,
                    tipo: e.target.value,
                  })
                }
              >
                <option
                  selected={
                    servicioSeleccionado &&
                    servicioSeleccionado.tipo === "remoto"
                  }
                  value="remoto"
                >
                  Remoto
                </option>
                <option
                  selected={
                    servicioSeleccionado &&
                    servicioSeleccionado.tipo === "presencial"
                  }
                  value="presencial"
                >
                  Presencial
                </option>
              </select>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModalEditar(false)}>
            Cancelar
          </Button>{" "}
          <Button variant="primary" onClick={editarServicio}>
            Editar
          </Button>{" "}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Profile;
