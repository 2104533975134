import { useParams } from "react-router-dom";
import { GetSingleApi } from "../api/conection";
import { useEffect, useState } from "react";
import "./css/recibo.css";
import Logo from "../img/logo_negro_sm.svg";

const Recibo = () => {
  let { id } = useParams();
  const [serviceRequest, setServiceRequest] = useState(null);

  useEffect(() => {
    fetchServiceRequest();
  }, []);

  const fetchServiceRequest = () => {
    GetSingleApi("/service_request", id)
      .then((res) => setServiceRequest(res.data[0]))
      .catch((error) => console.error(error));
  };

  if (!serviceRequest || !serviceRequest.cliente || !serviceRequest.service || !serviceRequest.recibo) {
    return (
      <div className="col-md-12 d-flex flex-column align-items-center justify-content-center">
        <img src={Logo} alt="Logo Near-u" height="100" id="loader" />
        <h4>Cargando...</h4>
      </div>
    );
  }

  return (
    <div className="container-body">
      <div className="header">
        <img src={Logo} alt="Logo Near-U" />
        <h1 style={{ color: "#fff" }}>Recibo</h1>
      </div>
      <div className="content">
        <div>
          <p>
            Cliente: {serviceRequest.cliente.firstname}{" "}
            {serviceRequest.cliente.lastname} ({serviceRequest.cliente.username})
          </p>
        </div>
        <div>
          <h3>Servicio:</h3>
          <ul>
            <li>{serviceRequest.service.nombre}</li>
          </ul>
        </div>
        <div style={{ textAlign: "right" }}>
          <h3>Total:</h3>
          <p>${serviceRequest.recibo.monto}</p>
        </div>
      </div>
    </div>
  );
};

export default Recibo;
