import React, { useEffect, useState } from "react";
import { GetSingleApi, PostApi } from "../api/conection";
/* global MercadoPago */

const Payments = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (localStorage.user_id) {
      fetchUser();
    }
  }, []);

  const fetchUser = () => {
    const { user_id } = localStorage;
    GetSingleApi("/user", user_id)
      .then((res) => setUser(res.data))
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    const initializeMercadoPago = async () => {
      const mp = new MercadoPago(
        "APP_USR-2b62f9d5-b784-447b-b4b9-c5c5a03a4aaf",
        {
          locale: "es-AR",
        }
      );

      const bricksBuilder = mp.bricks();

      const renderPaymentBrick = async (bricksBuilder) => {
        document.querySelector("#btn_pagar").style.display = "none";
        const items = [
          {
            category_id: 1,
            description: "Descripción del producto/servicio",
            id: 1,
            quantity: 1,
            title: "Nombre del producto/servicio",
            unit_price: 10000,
          },
        ];
        const settings = {
          initialization: {
            amount: 10000,
            preference: {
              payer: {
                address:
                  user && `${user.district}, ${user.province}, ${user.country}`,
                phone: user && user.phone,
                email: user && user.email,
              },
            },
          },
          additional_info: {
            items: items,
          },
          customization: {
            visual: {
              style: {
                theme: "default",
              },
            },
            paymentMethods: {
              creditCard: "all",
              debitCard: "all",
              ticket: "all",
              bankTransfer: "all",
              atm: "all",
              onboarding_credits: "all",
              wallet_purchase: "all",
              maxInstallments: 1,
            },
          },
          callbacks: {
            onReady: () => {
              // Callback llamado cuando el Brick está listo.
              // Aquí puedes ocultar cargamentos de tu sitio, por ejemplo.
            },
            onSubmit: async ({ selectedPaymentMethod, formData }) => {
              try {
                const response = await PostApi("/process_payment", formData);
                const externalUrl =
                  response.transaction_details.external_resource_url;
                window.open(externalUrl, "_blank");
                return Promise.resolve();
              } catch (error) {
                return Promise.reject(error);
              }
            },
            onError: (error) => {
              console.error(error);
            },
          },
        };
        window.paymentBrickController = await bricksBuilder.create(
          "payment",
          "paymentBrick_container",
          settings
        );
      };

      document.getElementById("btn_pagar").addEventListener("click", () => {
        renderPaymentBrick(bricksBuilder);
      });

      return () => {
        document
          .getElementById("btn_pagar")
          .removeEventListener("click", () => {
            renderPaymentBrick(bricksBuilder);
          });
      };
    };

    initializeMercadoPago();
  }, [user]);

  return (
    <div
      className="container"
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <button id="btn_pagar">Pagar</button>
      <div id="paymentBrick_container"></div>
    </div>
  );
};

export default Payments;
