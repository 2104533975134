import axios from "axios";

const Api = process.env.REACT_APP_API_URL;

const token = localStorage.getItem("token");
const headers = {
  Authorization: `Bearer ${token}`,
};

const GetApi = async (pathname, conditions = "") => {
  try {
    const response = await axios.get(`${Api}${pathname}${conditions}`, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

const GetOther = async (pathname) => {
  try {
    const response = await axios.get(`${pathname}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

const GetSingleApi = async (pathname, id) => {
  try {
    const response = await axios.get(`${Api}${pathname}/${id}`, { headers });
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

const PostApi = async (pathname, data) => {
  try {
    const response = await axios.post(`${Api}${pathname}`, data, {
      headers,
      "Content-Type": "multipart/form-data",
    });
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

const PutApi = async (pathname, id, data) => {
  try {
    const response = await axios.put(`${Api}${pathname}/${id}`, data, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error updating data:", error);
    throw error;
  }
};

const DeleteApi = async (pathname, id) => {
  try {
    const response = await axios.delete(`${Api}${pathname}/${id}`, { headers });
    return response.data;
  } catch (error) {
    console.error("Error deleting data:", error);
    throw error;
  }
};

export { GetApi, GetSingleApi, PostApi, PutApi, DeleteApi, GetOther };
