import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { io } from "socket.io-client";
import {
  GetSingleApi,
  GetApi,
  PostApi,
  PutApi,
  DeleteApi,
} from "../api/conection";
import { useMediaQuery } from "react-responsive";
import Logo from "../img/logo_negro_sm.svg";
import { renderToString } from "react-dom/server";

const Chat = () => {
  const [socket, setSocket] = useState(null);
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [userCurrentId, setUserCurrentId] = useState(null);
  const [userCurrent, setUserCurrent] = useState(null);
  const [showConfig, setShowConfig] = useState(false);
  const messagesContainerRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState("");
  const urlParams = new URLSearchParams(window.location.search);
  const isMobile = useMediaQuery({ maxWidth: 999 });

  useEffect(() => {
    async function fetchInitialData() {
      const chatParam = urlParams.get("selected-chat");
      if (chatParam) {
        try {
          await fetchChats(chatParam);
          await fetchMessages(chatParam);
        } catch (error) {
          console.error("Error fetching chat list:", error);
        }
      }
    }
    fetchInitialData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const userFromStorage = JSON.parse(localStorage.getItem("user_id"));
        const userData = await GetSingleApi("/user", parseInt(userFromStorage));
        setUserCurrentId(userData.data.id);
        setUserCurrent(userData.data);
        if (chats.length === 0 && !urlParams.get("selected-chat")) {
          fetchChats();
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    const newSocket = io(process.env.REACT_APP_SOCKET_URL);
    setSocket(newSocket);

    newSocket.on("connect", () => {
      console.log("Connected to Socket.IO server");
    });

    newSocket.on("disconnect", () => {
      console.log("Disconnected from Socket.IO server");
    });

    newSocket.on("chat_message", (message) => {
      setMessages((prevMessages) => [...prevMessages, message]);
    });

    return () => {
      newSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (selectedChat !== null) {
      fetchMessages(selectedChat.id);
    }
  }, [selectedChat]);

  useEffect(() => {
    if (selectedChat !== null) {
      fetchService(selectedChat.servicio_id);
    }
  }, [selectedChat, chats]);

  useLayoutEffect(() => {
    scrollToBottom();
    checkUnreadMessages();
  }, [messages]);

  const fetchService = async (service_id) => {
    try {
      const serviceList = await GetSingleApi(`/service`, service_id);
      selectedChat.services = serviceList.data;
      setSelectedChat(selectedChat);
    } catch (error) {
      console.error("Error fetching chat list:", error);
    }
  };

  const fetchChats = async (chat_id) => {
    if (chat_id) {
      try {
        const chatList = await GetApi(`/chats`, `?chat_id=${chat_id}`);
        setChats(chatList.data);
        setSelectedChat(chatList.data[0]);
      } catch (error) {
        console.error("Error fetching chat list:", error);
      }
    } else if (localStorage.user_id) {
      try {
        const chatList = await GetApi(
          `/chats`,
          `?user_id=${localStorage.user_id}`
        );
        setChats(chatList.data);
      } catch (error) {
        console.error("Error fetching chat list:", error);
      }
    }
  };

  const fetchMessages = async (chatId) => {
    try {
      const response = await GetApi(`/messages?chat_id=${chatId}`);
      setMessages(response.data);
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  const sendMessage = async (finalizado, mensaje) => {
    const id_finalizado = finalizado ? userCurrentId : null;
    try {
      await PostApi(`/messages`, {
        message: newMessage || mensaje,
        user_id: userCurrentId,
        chat_id: selectedChat.id,
        id_finalizado: id_finalizado,
      });
      setNewMessage("");
      fetchMessages(selectedChat.id);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const selectChat = (chat) => {
    setSelectedChat(chat);
  };

  const closeChat = () => {
    setSelectedChat(null);
    setMessages([]);
    if (urlParams.get("selected-chat")) {
      window.location.href = "/chat";
    }
  };

  const openConfig = () => {
    setShowConfig(!showConfig);
  };

  const changeBloqueoChat = (chat, bloqueo) => {
    const { id, user, nombre_cliente, nombre_vendedor, ...rest } = chat;
    rest.bloqueado = bloqueo;
    rest.updated_at = new Date();
    PutApi("/chat", chat.id, rest)
      .then(() => window.location.reload())
      .catch((error) => console.error(error));
  };

  const changeAceptarChat = (chat, activo) => {
    const { id, user, nombre_cliente, nombre_vendedor, ...rest } = chat;
    rest.activo = activo;
    rest.updated_at = new Date();
    PutApi("/chat", chat.id, rest)
      .then(() => {
        if (activo === 1) {
          const data = {
            vendedor_id: nombre_vendedor.id,
            cliente_id: nombre_cliente.id,
            service_id: rest.servicio_id,
            rating: null,
            activo: 1,
            terminado: 0,
          };
          PostApi("/service_request", data)
            .then(() => window.location.reload())
            .catch((error) => console.error(error));
        } else {
          window.location.reload();
        }
      })
      .catch((error) => console.error(error));
  };

  const terminarServicio = (chat, terminado) => {
    const { id, user, nombre_cliente, nombre_vendedor, services, ...rest } =
      chat;
    if (rest.user_id_client === userCurrentId) {
      if (rest.terminado_client !== terminado) {
        rest.terminado_client = terminado;
      } else {
        alert("Ya has finalizado el servicio");
        window.location.reload();
        return;
      }
    } else {
      if (rest.terminado_provider !== terminado) {
        rest.terminado_provider = terminado;
      } else {
        alert("Ya has finalizado el servicio");
        window.location.reload();
        return;
      }
    }
    rest.updated_at = new Date();
    if (rest.activo === 1) {
      if (rest.terminado_provider === 1 && rest.terminado_client === 1) {
        const service_requests = services.service_requests.find(
          (service_request) =>
            service_request.service_id === services.id &&
            (service_request.vendedor_id === userCurrentId ||
              service_request.cliente_id === userCurrentId)
        );
        if (service_requests) {
          service_requests.terminado = 1;
          service_requests.activo = 0;
          rest.activo = 3;
          PutApi("service_request", service_requests.id, service_requests)
            .then((res) => console.log(res))
            .catch((error) => console.error(error));
        }
      }
      PutApi("/chat", chat.id, rest)
        .then(() => {
          sendMessage(
            true,
            `${userCurrent.firstname} ${userCurrent.lastname} a finalizado el servicio`
          )
            .then(() => window.location.reload())
            .catch((error) => console.error(error));
        })
        .catch((error) => console.error(error));
    } else {
      if (rest.user_id_client === userCurrentId) {
        alert(
          "El creador del servicio no ha aceptado el servicio para poder finalizarlo"
        );
      } else {
        alert("No has aceptado el servicio para poder finalizarlo");
      }
    }
  };

  const generarFactura = async (chat) => {
    let monto = 0;

    monto = window.prompt("Introduzca el monto que cobró por el servicio:");

    if (monto !== null) {
      monto = parseFloat(monto);

      if (!isNaN(monto) && monto > 0) {
        const { id, user, nombre_cliente, nombre_vendedor, services, ...rest } =
          chat;
        const service_requests = services.service_requests.find(
          (service_request) =>
            service_request.service_id === services.id &&
            service_request.terminado === 1 &&
            (service_request.vendedor_id === userCurrentId ||
              service_request.cliente_id === userCurrentId)
        );
        try {
          await PostApi("/send-email", {
            to: "allariaemanuel@gmail.com",
            subject: "Recibo de finalización del servicio.",
            clienteName: `${nombre_cliente.firstname} ${nombre_cliente.lastname}`,
            serviceName: services.nombre,
            monto: monto.toFixed(2),
          })
            .then(() => {
              PostApi("/recibo", {
                user_id: nombre_cliente.id,
                service_request_id: service_requests.id,
                monto: monto,
              })
                // .then(() => window.location.reload())
                .catch((error) => console.error(error));
            })
            .catch((error) => console.error(error));
        } catch (error) {
          console.error("Error al enviar el correo:", error);
          alert("Error al enviar el correo");
        }
      } else {
        setErrorMessage("Por favor, introduzca un monto válido.");
        return;
      }
    }
  };

  const valorar = (chat, valorarA) => {
    let userRating = 0;
    let userComment = "";

    const userRatingInput = window.prompt(
      "Introduzca el valor entre 1 a 5 de rating:"
    );

    if (userRatingInput !== null) {
      userRating = parseFloat(userRatingInput);

      if (!isNaN(userRating) && userRating >= 1 && userRating <= 5) {
        setErrorMessage("");

        userComment = window.prompt("Introduzca su comentario:");
        if (userComment === null || userComment.trim() === "") {
          setErrorMessage("Por favor, introduzca un comentario.");
          return;
        }

        const { id, user, nombre_cliente, nombre_vendedor, services, ...rest } =
          chat;
        const service_requests = services.service_requests.find(
          (service_request) =>
            service_request.service_id === services.id &&
            service_request.terminado === 1 &&
            (service_request.vendedor_id === userCurrentId ||
              service_request.cliente_id === userCurrentId)
        );

        if (valorarA === "Cliente") {
          const rating = {
            user_id: nombre_cliente.id,
            service_id: null,
            rating: userRating,
            comentario: userComment,
          };
          PostApi("/rating", rating)
            .then(() => {
              rest.terminado_provider = 2;
              PutApi("/chat", id, rest)
                .then(() => window.location.reload())
                .catch((err) => console.error(err));
            })
            .catch((err) => console.error(err));
        } else {
          const rating = {
            user_id: null,
            service_id: services.id,
            rating: userRating,
            comentario: userComment,
          };
          PostApi("/rating", rating)
            .then(() => {
              rest.terminado_client = 2;
              PutApi("/chat", id, rest)
                .then(() => window.location.reload())
                .catch((err) => console.error(err));
            })
            .catch((err) => console.error(err));
        }
      } else {
        setErrorMessage("Por favor, introduzca un número válido entre 1 y 5.");
        return;
      }
    }
  };

  const eliminarChat = (id_chat) => {
    DeleteApi("/chat", id_chat)
      .then(() => window.location.reload())
      .catch((error) => console.error(error));
  };

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      sendMessage();
    }
  };

  const checkUnreadMessages = () => {
    const unreadMessages = messages.filter(
      (message) => parseInt(message.user_id) !== userCurrentId
    );
    if (unreadMessages.length > 0) {
      console.log("New unread messages:", unreadMessages);
    }
  };

  const formatoHorario = (horario) => {
    const fecha = new Date(horario);
    const dia = fecha.getDate();
    const mes = fecha.getMonth() + 1;
    const año = fecha.getFullYear();
    const horas = fecha.getHours();
    const minutos = fecha.getMinutes();
    return `${dia}/${mes}/${año} ${horas}:${minutos < 10 ? "0" : ""}${minutos}`;
  };

  return (
    <div
      style={{
        width: "100%",
        padding: "20px 50px",
        background: "linear-gradient(270deg, #375189, #308ab4)",
        borderRadius: "0 0 20px 20px",
        height: isMobile && selectedChat ? "55em" : "",
      }}
    >
      <h1 style={{ textAlign: "center", color: "#000" }}>NEAR-U</h1>
      {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
      <div className="row" style={{ height: 500, borderRadius: 20 }}>
        <div
          className="col-md-3 bg-light border-right p-0"
          style={{ maxHeight: 500, overflowY: "auto" }}
        >
          <div className="chat-list">
            <h5 className="px-3 py-2 mb-0">Chats</h5>
            <ul
              className="list-group list-group-flush"
              style={{ gap: "0.1em" }}
            >
              {chats.map((chat) => (
                <li
                  key={chat.id}
                  className="list-group-item cursor-pointer d-flex align-items-center"
                  onClick={() => selectChat(chat)}
                  style={{
                    minHeight: 50,
                    maxHeight: 150,
                    background: chat.activo === 2 ? "#e3e3e3" : "#fff",
                    position: "relative",
                  }}
                >
                  <img
                    height={50}
                    width={50}
                    src={`${process.env.REACT_APP_IMAGES_URL}${
                      userCurrentId === chat?.user_id_client
                        ? chat?.nombre_vendedor?.image
                        : chat?.nombre_cliente?.image
                    }`}
                    alt=""
                  />
                  <p
                    className="my-0"
                    title={`
                      ${
                        userCurrentId === chat?.user_id_client
                          ? chat?.nombre_vendedor?.firstname
                          : chat?.nombre_cliente?.firstname
                      } ${
                      userCurrentId === chat?.user_id_client
                        ? chat?.nombre_vendedor?.lastname
                        : chat?.nombre_cliente?.lastname
                    }
                    `}
                  >
                    {userCurrentId === chat?.user_id_client
                      ? chat?.nombre_vendedor?.firstname
                      : chat?.nombre_cliente?.firstname}{" "}
                    {userCurrentId === chat?.user_id_client
                      ? chat?.nombre_vendedor?.lastname
                      : chat?.nombre_cliente?.lastname}{" "}
                    <br /> {chat.servicio}
                  </p>
                  {chat.user_id_provider === userCurrentId &&
                    chat.activo === 0 && (
                      <div
                        className="btns-configs"
                        style={{ marginLeft: "15%" }}
                      >
                        <button
                          className="btn btn-success btn-sm"
                          onClick={() => changeAceptarChat(chat, 1)}
                          title="Aceptar Servicio"
                        >
                          <i class="fa-solid fa-check"></i>
                        </button>
                        <button
                          className="btn btn-cancel btn-sm"
                          onClick={() => changeAceptarChat(chat, 2)}
                          title="Rechazar Servicio"
                        >
                          <i class="fa-solid fa-xmark"></i>
                        </button>
                      </div>
                    )}
                  {chat.activo === 2 && (
                    <div
                      className="btns-configs"
                      style={{ marginLeft: "15%", fontSize: "2em" }}
                      title="Servicio Rechazado"
                    >
                      <i class="fa-solid fa-xmark"></i>
                    </div>
                  )}
                  {chat.activo === 3 && (
                    <div
                      className="btns-configs"
                      style={{ marginLeft: "15%", fontSize: "2em" }}
                      title="Servicio Finalizado"
                    >
                      <i class="fa-solid fa-check"></i>
                    </div>
                  )}
                  {chat.bloqueado === 1 && (
                    <i
                      class="fa-solid fa-triangle-exclamation"
                      style={{
                        position: "absolute",
                        right: 10,
                        fontSize: "1.5em",
                      }}
                      title="Bloqueado"
                    ></i>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="col-md-9 p-0">
          {selectedChat ? (
            <div className="message-list d-flex flex-column justify-content-between py-3 px-3 h-100 bg-light">
              <div className="d-flex justify-content-between align-items-center gap-3">
                <h5
                  style={{
                    fontSize: isMobile ? "0.9em" : "",
                    textWrap: isMobile ? "balance" : "",
                  }}
                >
                  {userCurrentId === selectedChat?.user_id_client
                    ? selectedChat?.nombre_vendedor?.firstname
                    : selectedChat?.nombre_cliente?.firstname}{" "}
                  {userCurrentId === selectedChat?.user_id_client
                    ? selectedChat?.nombre_vendedor?.lastname
                    : selectedChat?.nombre_cliente?.lastname}{" "}
                  | {selectedChat?.servicio}
                </h5>
                <div
                  className="d-flex align-items-center mb-3"
                  style={{ position: "relative" }}
                >
                  <button className="btn btn-danger btn-sm" onClick={closeChat}>
                    Close
                  </button>
                  <button className="btn btn-sm" onClick={openConfig}>
                    •••
                  </button>
                  {showConfig && (
                    <ul
                      style={{
                        position: "absolute",
                        top: "2em",
                        right: "0",
                        background: "#fff",
                        padding: "1em",
                        listStyle: "none",
                        minInlineSize: "max-content",
                      }}
                    >
                      {selectedChat.activo === 1 &&
                        (selectedChat.terminado_client === 0 ||
                          selectedChat.terminado_provider === 0) &&
                        (selectedChat.user_id_client === userCurrentId ||
                          selectedChat.user_id_provider === userCurrentId) && (
                          <li
                            style={{ cursor: "pointer", marginBottom: "0.5em" }}
                            onClick={() => terminarServicio(selectedChat, 1)}
                          >
                            Servicio Terminado
                          </li>
                        )}
                      {selectedChat.activo === 3 &&
                        selectedChat.terminado_client === 2 &&
                        selectedChat.terminado_provider === 2 &&
                        selectedChat.user_id_provider === userCurrentId && (
                          <li
                            style={{ cursor: "pointer", marginBottom: "0.5em" }}
                            onClick={() => generarFactura(selectedChat)}
                          >
                            Generar Factura
                          </li>
                        )}
                      {selectedChat.activo === 3 ? (
                        (selectedChat.terminado_client === 1 &&
                          selectedChat.user_id_provider !== userCurrentId) ||
                        (selectedChat.terminado_provider === 1 &&
                          selectedChat.user_id_provider === userCurrentId) ? (
                          <li
                            style={{ cursor: "pointer", marginBottom: "0.5em" }}
                            onClick={() =>
                              valorar(
                                selectedChat,
                                selectedChat.user_id_provider === userCurrentId
                                  ? "Cliente"
                                  : "Vendedor"
                              )
                            }
                          >
                            {selectedChat.user_id_provider === userCurrentId
                              ? "Valorar Cliente"
                              : "Valorar Servicio"}
                          </li>
                        ) : null
                      ) : null}
                      {selectedChat.bloqueado === 0 ? (
                        <li
                          style={{ cursor: "pointer" }}
                          onClick={() => changeBloqueoChat(selectedChat, 1)}
                        >
                          Bloquear
                        </li>
                      ) : selectedChat.bloqueado === 1 ? (
                        <li
                          style={{ cursor: "pointer" }}
                          onClick={() => changeBloqueoChat(selectedChat, 0)}
                        >
                          Desbloquear
                        </li>
                      ) : null}
                      <li
                        style={{ cursor: "pointer", marginTop: "0.5em" }}
                        onClick={() => eliminarChat(selectedChat.id)}
                      >
                        Eliminar Chat
                      </li>
                    </ul>
                  )}
                </div>
              </div>
              <div
                className="messages"
                style={{ height: "100%", maxHeight: 350, overflowY: "auto" }}
                ref={messagesContainerRef}
              >
                {messages &&
                  messages.map((message, index) => {
                    console.log(message);
                    return (
                      <div
                        key={index}
                        className={
                          message.message.includes(
                            "a finalizado el servicio"
                          ) || parseInt(message.id_finalizado)
                            ? "message-center"
                            : parseInt(message.user_id) === userCurrentId
                            ? "message-right"
                            : parseInt(message.user_id) !== userCurrentId
                            ? "message-left"
                            : null
                        }
                      >
                        <p>
                          {message.message}{" "}
                          {!message.message.includes(
                            "a finalizado el servicio"
                          ) ||
                            (parseInt(message.id_finalizado) === null && (
                              <span>{formatoHorario(message.updated_at)}</span>
                            ))}
                        </p>
                      </div>
                    );
                  })}
              </div>
              {selectedChat.bloqueado === 0 &&
              selectedChat.terminado_client === 0 &&
              selectedChat.terminado_provider === 0 &&
              selectedChat.activo !== 2 ? (
                <div className="input-group mt-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Escriba su mensaje..."
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    onKeyPress={handleKeyPress}
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={sendMessage}
                    >
                      Enviar
                    </button>
                  </div>
                </div>
              ) : (selectedChat.terminado_client === 1 &&
                  selectedChat.terminado_provider === 1) ||
                (selectedChat.terminado_client === 2 &&
                  selectedChat.terminado_provider === 2) ? (
                <div
                  className="input-group mt-3"
                  style={{ textAlign: "center" }}
                >
                  <p>No se puede enviar mensaje, el servicio a terminado.</p>
                </div>
              ) : selectedChat.bloqueado === 1 ? (
                <div
                  className="input-group mt-3"
                  style={{ textAlign: "center" }}
                >
                  <p>No se puede enviar mensaje a un chat bloqueado.</p>
                </div>
              ) : selectedChat.activo === 2 ? (
                <div
                  className="input-group mt-3"
                  style={{ textAlign: "center" }}
                >
                  <p>
                    No se puede enviar mensaje ya que el servicio fué rechazado.
                  </p>
                </div>
              ) : null}
            </div>
          ) : (
            <div className="d-flex justify-content-center align-items-center h-100 bg-light">
              <img src={Logo} className="img-fluid" alt="Logo Near-U" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Chat;
