import Logo from "../img/logo_negro_sm.svg";

const Page404 = () => {
  return (
    <div
      style={{
        width: "100%",
        padding: 20,
        background: "linear-gradient(270deg, #375189, #308ab4)",
        borderRadius: "0 0 20px 20px",
      }}
    >
      <div className="column">
        <h1 style={{ textAlign: "center", color: "#000" }}>NEAR-U</h1>
        <div className="col-lg-12 mb-4">
          <div className="card text-white">
            <div className="card-body text-center">
              <img src={Logo} alt="Logo Near-u" height="100" />
              <h2 className="text-center">
                Error 404 <br /> (Pagina no encontrada)
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page404;
