import React from "react";
import { Container, Typography, Box, Paper } from "@mui/material";
import "./css/AcercaDe.css";

const PrivacyPolicy = () => {
  return (
    <div
      style={{
        width: "100%",
        background: "linear-gradient(270deg, #375189, #308ab4)",
        borderRadius: "0 0 20px 20px",
        padding: 20,
      }}
    >
      <div className="descripcion-relativo bg-white rounded p-3">
        <div className="titulo-absoluto bg-white rounded p-3 text-black">
          <h2>Políticas y Privacidad de Near-U</h2>
        </div>
        <p className="text-center">
          En Near-U, nos tomamos muy en serio la privacidad de nuestros
          usuarios. A continuación, se detallan nuestras políticas de
          privacidad:
        </p>
      </div>
      <br />
      <div className="descripcion-relativo bg-white rounded p-3">
        <div className="titulo-absoluto bg-white rounded p-3 text-black">
          <h2>Recopilación de Información</h2>
        </div>
        <p className="text-center">
          Near-U recopila información personal y de ubicación solo con el
          consentimiento explícito de los usuarios. Esta información se utiliza
          para mejorar la experiencia de usuario y no se comparte con terceros
          sin autorización.
        </p>
      </div>
      <br />
      <div className="descripcion-relativo bg-white rounded p-3">
        <div className="titulo-absoluto bg-white rounded p-3 text-black">
          <h2>Uso de la Información</h2>
        </div>
        <p className="text-center">
          La información recopilada por Near-U se utiliza para proporcionar
          servicios personalizados basados en la ubicación del usuario y sus
          preferencias. Los datos no se utilizan con fines publicitarios sin
          consentimiento.
        </p>
      </div>
      <br />
      <div className="descripcion-relativo bg-white rounded p-3">
        <div className="titulo-absoluto bg-white rounded p-3 text-black">
          <h2>Seguridad</h2>
        </div>
        <p className="text-center">
          Implementamos medidas de seguridad avanzadas para proteger la
          información de nuestros usuarios contra accesos no autorizados o uso
          indebido.
        </p>
      </div>
      <br />
      <div className="descripcion-relativo bg-white rounded p-3">
        <div className="titulo-absoluto bg-white rounded p-3 text-black">
          <h2>Chat y Comunicaciones</h2>
        </div>
        <p className="text-center">
          El chat en tiempo real de Near-U es privado y solo visible para las
          partes involucradas. Las conversaciones se almacenan de forma segura y
          no se comparten con otros usuarios o entidades externas.
        </p>
      </div>
      <br />
      <div className="descripcion-relativo bg-white rounded p-3">
        <div className="titulo-absoluto bg-white rounded p-3 text-black">
          <h2>Actualizaciones de Políticas</h2>
        </div>
        <p className="text-center">
          Near-U se reserva el derecho de actualizar estas políticas de
          privacidad periódicamente. Los usuarios serán notificados de cualquier
          cambio importante a través de nuestras plataformas.
        </p>
      </div>
      <br />
      <div className="descripcion-relativo bg-white rounded p-3 pt-inherit">
        <p className="text-center">
          Para preguntas o inquietudes sobre nuestras políticas de privacidad,
          por favor contáctenos a través de{" "}
          <a
            className="text-decoration-none"
            href="mailto:dev@typingtiming.com.ar"
          >
            dev@typingtiming.com.ar
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
